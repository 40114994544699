
.App {
  text-align: center;
}

.card {
  width: 24rem;
  margin: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}